<template>
  <div class="artist-profile">
    <!-- 메뉴 영역: 아티스트 ID 및 섹션 선택 -->
    <div class="artist-profile__menu-wrap">
      <div class="artist-profile__menu-title"> 
        <h4><strong>{{ artistId }}</strong></h4> 
      </div>
      <div class="artist-profile__info-wrap">
        <!-- Works 섹션 (기본) -->
        <div 
          :class="['artist-profile__info', { 'artist-profile__info--active': currentSection === null }]" 
          @click="setCurrentSection(null)"
        >
          <h4 class="artist-profile__info-text">Works</h4>
        </div>
        <!-- CV 섹션 -->
        <div 
          :class="['artist-profile__info', { 'artist-profile__info--active': currentSection === 'cv' }]" 
          @click="setCurrentSection('cv')"
        >
          <h4 class="artist-profile__info-text">CV</h4>
        </div>
        <!-- Text 섹션 -->
        <div 
          :class="['artist-profile__info', { 'artist-profile__info--active': currentSection === 'text' }]" 
          @click="setCurrentSection('text')"
        >
          <h4 class="artist-profile__info-text">Text</h4>
        </div>
      </div>
    </div>

    <!-- 콘텐츠 영역 -->
    <div class="artist-profile__contents-wrap">
      <!-- CV 섹션 -->
      <div v-if="currentSection === 'cv'" class="artist-profile__cv">
        <ArtistCV :artistId="artistId" />
      </div>
      <!-- Text 섹션 -->
      <div v-if="currentSection === 'text'" class="artist-profile__text">
        <ArtistText :artistId="artistId" />
      </div>
      <!-- 작업 상세보기: 선택된 작업이 있으면 WorkDetailComp 렌더링 -->
      <WorkDetailComp v-if="selectedWorkId" :artistId="artistId" :workId="selectedWorkId" />
      <!-- 작업 목록: 작업 상세가 선택되지 않았고 Works 섹션일 때 ThumbnailComp 렌더링 -->
      <div v-if="!selectedWorkId && currentSection === null" class="artist-profile__img-wrap">
        <ThumbnailComp 
          :thumbnails="currentThumbnails" 
          :artistId="artistId" 
          @workSelected="showWorkDetail" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import ThumbnailComp from '@/components/ThumbnailComp';
import ArtistCV from '@/components/ArtistCV';
import ArtistText from '@/components/ArtistText';
import WorkDetailComp from '@/components/WorkDetailComp.vue';
import artistThumbnails from '@/data/artistThumbnails';
import artistData from '@/data/artistData.json';

export default {
  name: 'ArtistProfile',
  components: {
    ThumbnailComp,
    ArtistCV,
    ArtistText,
    WorkDetailComp,
  },
  props: {
    artistId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentSection: null, // 섹션 선택: null은 Works, 'cv'는 CV, 'text'는 Text
      selectedWorkId: null, // 선택된 작업 ID (WorkDetailComp 렌더링 제어)
      // 불변 데이터인 artistThumbnails를 그대로 사용
      artistThumbnails,
      artistInfo: null, // 아티스트 정보 (schema 마크업에 사용)
    };
  },
  computed: {
    // 현재 아티스트의 썸네일 데이터
    currentThumbnails() {
      return this.artistThumbnails[this.artistId] || [];
    },
  },
  watch: {
    '$route'(to) {
      // 라우트가 변경될 때:
      // workId가 없으면 Works 섹션으로 초기화, workId가 있으면 상세보기 표시
      if (to.name === 'ArtistProfileView' && !to.params.workId) {
        this.setCurrentSection(null);
        this.selectedWorkId = null;
      } else if (to.params.workId) {
        this.showWorkDetail(to.params.workId);
      }
    },
    // artistInfo가 변경되면 스키마 마크업 업데이트
    artistInfo() {
      this.updateSchemaMarkup();
    },
  },
  mounted() {
    // 마운트 시 아티스트 정보를 가져오고 스키마 마크업 추가
    this.artistInfo = artistData[this.artistId] || null;
    this.updateSchemaMarkup();
  },
  beforeUnmount() {
    // 컴포넌트 파기 시 스키마 스크립트 제거
    const existingSchema = document.getElementById('artist-schema');
    if (existingSchema) {
      existingSchema.remove();
    }
  },
  methods: {
    /**
     * 섹션 변경 처리
     * @param {string|null} section - 'cv', 'text' 또는 null(Works)
     */
    setCurrentSection(section) {
      this.currentSection = section;
      this.selectedWorkId = null; // 섹션 전환 시 선택 작업 초기화
    },
    /**
     * 작업 상세보기 설정
     * @param {string} workId - 선택된 작업의 ID
     */
    showWorkDetail(workId) {
      this.selectedWorkId = workId;
    },
    /**
     * 스키마 마크업 업데이트 (JSON-LD)
     */
    updateSchemaMarkup() {
      if (!this.artistInfo) return;
      const schemaId = 'artist-schema';
      let schemaScript = document.getElementById(schemaId);

      const schemaData = {
        "@context": "https://schema.org",
        "@type": "Person",
        "name": this.artistInfo?.name,
        "jobTitle": this.artistInfo?.jobTitle,
        "worksFor": {
          "@type": "Organization",
          "name": this.artistInfo?.worksFor?.name,
        },
        "url": this.artistInfo?.url,
        "description": this.artistInfo?.description,
      };

      if (schemaScript) {
        // 이미 존재하면 내용만 업데이트
        schemaScript.text = JSON.stringify(schemaData);
      } else {
        // 스크립트 생성 후 헤드에 삽입
        schemaScript = document.createElement('script');
        schemaScript.setAttribute('id', schemaId);
        schemaScript.setAttribute('type', 'application/ld+json');
        schemaScript.text = JSON.stringify(schemaData);
        document.head.appendChild(schemaScript);
      }
    },
  },
};
</script>

<style scoped>
.artist-profile {
  width: 100%;
}

.artist-profile__menu-wrap {
  display: flex;
  margin-bottom: 1.2em;
  justify-content: flex-start;
  width: 100%;
}

.artist-profile__menu-title {
  width: 90%;
  /* 인라인 스타일 대신 CSS로 관리 */
}

.artist-profile__info-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.artist-profile__info {
  flex: 1 1 auto;
  position: relative;
}

.artist-profile__info--active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #c41a22;
}

.artist-profile__info:hover {
  cursor: pointer;
  transition: transform 0.3s ease;
}

/* CV 및 Text 섹션의 레이아웃 */
.artist-profile__cv,
.artist-profile__text {
  display: flex;
  flex-direction: column;
}

.artist-profile__contents-wrap {
  width: 100%;
}

.artist-profile__info-text {
  text-align: center;
}
</style>