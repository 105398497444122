<template>
  <div class="artist-gallery" @wheel="handleWheelScroll">
    <div class="artist-gallery__container-wrap">
      <!-- 스크롤 컨테이너 -->
      <div ref="scrollContainer" class="artist-gallery__thumbnail-wrap">
        <div
          v-for="(media, index) in artistImages"
          :key="index"
          class="artist-gallery__image-item"
        >
          <div class="artist-gallery__img-wrap" @click="handleOpenLightbox(index)">
            <!-- 이미지 렌더링 -->
            <img 
              v-if="media.mediaType === 'image'" 
              v-lazy="media.src" 
              :alt="media.description" 
            />
            <!-- Vimeo 영상 렌더링 (iframe 태그 그대로 삽입) -->
            <div 
              v-else-if="media.mediaType === 'externalIframe'" 
              v-html="media.iframeContent"
            ></div>
          </div>
        </div>
      </div>
      
      <!-- 타이틀 영역 -->
      <div class="artist-gallery__title-wrap">
        <h5 class="artist-gallery__title">
          {{ artistImages.length > 0 ? artistImages[0].description : 'No Title & Media' }}
        </h5>
      </div>
    </div>

    <!-- Vue-Easy-Lightbox -->
    <easy-lightbox 
      :visible="isLightboxVisible" 
      :imgs="lightboxImages" 
      :index="lightboxIndex" 
      @hide="handleHideLightbox" 
      :move-disabled="true"
      :swipeTolerance="50"
      :loop="true"
    ></easy-lightbox>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import artistWorkDetail from '@/data/artistWorkDetail';

/**
 * ArtistGallery.vue
 * 특정 작가(artistId)의 작품(workId) 목록(이미지, iframe 등)을 가로 스크롤로 표시하고,
 * 클릭 시 Easy-Lightbox로 이미지를 확대해서 볼 수 있도록 하는 컴포넌트
 */
export default {
  name: 'ArtistGallery',
  props: {
    artistId: {
      type: String,
      required: true
    },
    workId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    // 라이트박스 표시 여부
    const isLightboxVisible = ref(false);
    // 라이트박스에 표시할 현재 이미지 인덱스
    const lightboxIndex = ref(0);
    // 스크롤 컨테이너 DOM
    const scrollContainer = ref(null);

    /**
     * 작가의 작품 데이터에서 media 배열을 가져옴
     * - artistWorkDetail[artistId][workId] 구조
     * - 없으면 빈 배열 반환
     */
    const artistImages = computed(() => {
      return artistWorkDetail[props.artistId]?.[props.workId] || [];
    });

    /**
     * 라이트박스에 전달할 이미지 src 배열
     * - mediaType === 'image'인 항목만 필터링
     */
    const lightboxImages = computed(() => {
      return artistImages.value
        .filter((media) => media.mediaType === 'image')
        .map((image) => image.src);
    });

    /**
     * 이미지 클릭 시 라이트박스 열기
     * - 클릭한 미디어가 이미지인 경우에만 열림
     */
    const handleOpenLightbox = (index) => {
      if (artistImages.value[index]?.mediaType !== 'image') return;
      lightboxIndex.value = index;
      isLightboxVisible.value = true;
    };

    /**
     * 라이트박스 닫기
     */
    const handleHideLightbox = () => {
      isLightboxVisible.value = false;
    };

    /**
     * 마우스 휠로 가로 스크롤을 제어
     * - deltaY, deltaX를 합산해 scrollLeft를 이동
     */
    const handleWheelScroll = (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (scrollContainer.value) {
        scrollContainer.value.scrollLeft += event.deltaY + event.deltaX;
      }
    };

    /**
     * 스크롤 위치 초기화
     */
    const resetScroll = () => {
      if (scrollContainer.value) {
        scrollContainer.value.scrollLeft = 0;
      }
    };

    // 컴포넌트가 마운트된 후 스크롤 초기화
    onMounted(() => {
      resetScroll();
    });

    // artistId 또는 workId가 변경될 때마다 스크롤 초기화
    watch(
      () => [props.artistId, props.workId],
      () => {
        resetScroll();
      }
    );

    return {
      // 상태
      isLightboxVisible,
      lightboxIndex,
      scrollContainer,

      // 계산
      artistImages,
      lightboxImages,

      // 메서드
      handleOpenLightbox,
      handleHideLightbox,
      handleWheelScroll
    };
  }
};
</script>

<style scoped>
/* 전체 컨테이너 */
.artist-gallery {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* 갤러리 스크롤 영역 */
.artist-gallery__thumbnail-wrap {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden; /* 상하 스크롤 숨김 */
  padding-bottom: 10px;
  white-space: nowrap; /* 자식 요소들이 한 줄로 표시 */
}

/* 각 미디어 아이템 컨테이너 */
.artist-gallery__image-item {
  display: inline-block;
  margin-right: 10px;
}

/* 이미지 스타일 */
.artist-gallery__img-wrap img {
  max-height: 700px;
  object-fit: contain;
  cursor: pointer;
}

/* iframe(비디오) 스타일 */
.artist-gallery__img-wrap iframe {
  width: 100%;
  height: 100%;
  border: none;
  object-fit: cover;
}

/* 모바일 대응 */
@media screen and (max-width: 768px) {
  .artist-gallery__img-wrap img {
    max-height: 500px;
    object-fit: contain;
  }
}
</style>