import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/global.css';
import VueLazyLoad from 'vue-lazyload';
import EasyLightbox from 'vue-easy-lightbox';
import VueGtag from 'vue-gtag-next';


// 환경 변수 설정
const isProduction = process.env.NODE_ENV === 'production';

// 프로덕션 환경에서 HTTP 요청을 HTTPS로 리다이렉션
if (isProduction && window.location.protocol === 'http:') {
  const { hostname, pathname, search } = window.location;
  window.location.replace(`https://${hostname}${pathname}${search}`);
}

const app = createApp(App);

// 라우터 후처리: 페이지 제목 설정 및 페이지뷰 추적
router.afterEach((to) => {
  const pageTitle = to.meta?.title ?? '시작 SIZAK';
  document.title = pageTitle;

  if (typeof window.gtag === 'function') {
    window.gtag('event', 'page_view', {
      page_path: to.path,
      page_title: pageTitle,
    });
  }
});

// 플러그인 및 컴포넌트 등록
app.use(VueLazyLoad);
app.component('EasyLightbox', EasyLightbox);

app.use(
  VueGtag,
  {
    property: {
      id: process.env.VUE_APP_GA_MEASUREMENT_ID,
    },
    isEnabled: isProduction,
  },
  router
);

app.use(router).mount('#app');