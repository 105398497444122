import { createRouter, createWebHistory } from 'vue-router';
import MainView from '@/views/MainView.vue';
import PublicView from '@/views/PublicView.vue';
import AboutView from '@/views/AboutView.vue';
import ProjectView from '@/views/ProjectView.vue';
import ArtistProfileView from '@/views/ArtistProfileView.vue';
import ArtistDetailView from '@/views/ArtistDetailView.vue';

const routes = [
  {
    path: '/',
    name: 'MainView',
    component: MainView,
    meta: { 
      title: 'Sizak 시작',
      metaTags: [
        {
          name: 'description',
          content: 'Sizak 시작은 중앙대학교 사진학과의 젊은 작가들의 작품들을 소개하는 플랫폼입니다.'
        },
        {
          property: 'og:title',
          content: 'Sizak 시작'
        },
        {
          property: 'og:description',
          content: 'Sizak 시작은 중앙대학교 사진학과의 젊은 작가들의 작품들을 소개하는 플랫폼입니다.'
        }
      ]
    },
  },
  {
    path: '/about',
    name: 'AboutView',
    component: AboutView,
    meta: { 
      title: 'About',
      metaTags: [
        {
          name: 'description',
          content: 'About 페이지 - Sizak의 작가 및 프로젝트를 소개합니다.'
        },
        {
          property: 'og:title',
          content: 'About - Sizak'
        },
        {
          property: 'og:description',
          content: 'About 페이지 - Sizak의 작가 및 프로젝트를 소개합니다.'
        }
      ]
    },
  },
  {
    path: '/publication',
    name: 'PublicView',
    component: PublicView,
    meta: { 
      title: 'Publication',
      metaTags: [
        {
          name: 'description',
          content: 'Publication 페이지 - 작품 출판 및 소개.'
        },
        {
          property: 'og:title',
          content: 'Publication - Sizak'
        },
        {
          property: 'og:description',
          content: 'Publication 페이지 - 작품 출판 및 소개.'
        }
      ]
    },
  },
  {
    path: '/project',
    name: 'ProjectView',
    component: ProjectView,
    meta: { 
      title: 'Project',
      metaTags: [
        {
          name: 'description',
          content: 'Project 페이지 - 진행 중인 프로젝트와 작품 소개.'
        },
        {
          property: 'og:title',
          content: 'Project - Sizak'
        },
        {
          property: 'og:description',
          content: 'Project 페이지 - 진행 중인 프로젝트와 작품 소개.'
        }
      ]
    },
  },
  {
    path: '/artists/:artistId',
    name: 'ArtistProfileView',
    component: ArtistProfileView,
    props: true,
    meta: { 
      title: 'Artist Profile',
      metaTags: [
        {
          name: 'description',
          content: '작가 프로필 페이지 - 작품 및 이력 소개.'
        },
        {
          property: 'og:title',
          content: 'Artist Profile - Sizak'
        },
        {
          property: 'og:description',
          content: '작가 프로필 페이지 - 작품 및 이력 소개.'
        }
      ]
    },
    children: [
      {
        path: ':workId',
        name: 'ArtistDetailView',
        component: ArtistDetailView,
        props: true,
        meta: { 
          title: 'Artist Detail - Sizak',
          metaTags: [
            {
              name: 'description',
              content: '작품 상세 페이지 - 작가의 작품 상세 정보.'
            },
            {
              property: 'og:title',
              content: 'Artist Detail - Sizak'
            },
            {
              property: 'og:description',
              content: '작품 상세 페이지 - 작가의 작품 상세 정보.'
            }
          ]
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // 페이지 이동 시 스크롤 위치를 항상 최상단으로 설정
  scrollBehavior() {
    return { top: 0 };
  },
});

// 라우트 전환 후 타이틀 및 메타 태그 업데이트와 함께
// 구글 애널리틱스에 페이지 뷰 이벤트 전송
router.afterEach((to) => {
  // 문서 제목 업데이트
  const defaultTitle = 'Sizak 시작';
  document.title = to.meta.title || defaultTitle;

  // 기존 meta 태그 제거 (data-vue-router-controlled로 구분)
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).forEach(el => el.parentNode.removeChild(el));

  // 현재 라우트의 metaTags가 있다면 새로 추가
  if (to.meta.metaTags) {
    to.meta.metaTags.forEach(tagDef => {
      const tag = document.createElement('meta');
      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });
      tag.setAttribute('data-vue-router-controlled', '');
      document.head.appendChild(tag);
    });
  }

  // 동적 라우트의 경우, artistId와 workId를 포함하여 명확한 페이지 경로 설정
  let pagePath = to.fullPath; // 기본값: 전체 경로
  if (to.name === 'ArtistProfileView') {
    pagePath = `/artists/${to.params.artistId}`;
  } else if (to.name === 'ArtistDetailView') {
    pagePath = `/artists/${to.params.artistId}/${to.params.workId}`;
  }

  // 구글 애널리틱스(ga) 페이지뷰 이벤트 전송
  if (typeof window.gtag === 'function') {
    window.gtag('event', 'page_view', {
      page_path: pagePath,
      page_title: document.title,
    });
  }
});

export default router;