<template>
  <div class="thumbnail-container">
    <div 
      class="thumbnail-container__wrap" 
      v-for="(thumbnail, index) in thumbnails" 
      :key="thumbnail.id || index"
      @click="handleSelectWork(thumbnail.id)"
    >
      <!-- router-link를 사용하여 ArtistDetailView로 이동 -->
      <router-link 
        :to="{ name: 'ArtistDetailView', params: { artistId, workId: thumbnail.id } }"
      >
        <div class="thumbnail-container__image">
          <img 
            :src="thumbnail.lowResSrc" 
            :data-src="thumbnail.src" 
            :alt="thumbnail.description" 
            @load="handleLoadHighResImage"
          />
          <div class="thumbnail-container__description">
            {{ thumbnail.description }}
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThumbnailComp',
  props: {
    artistId: {
      type: String,
      required: true
    },
    thumbnails: {
      type: Array,
      required: true
    }
  },
  methods: {
    /**
     * 선택한 작업(work)의 ID를 부모 컴포넌트에 emit
     */
    handleSelectWork(workId) {
      this.$emit('workSelected', workId);
    },
    /**
     * 이미지 로드 시, data-src에 설정된 고해상도 이미지로 교체
     */
    handleLoadHighResImage(event) {
      const img = event.target;
      const highResSrc = img.getAttribute('data-src');
      if (highResSrc) {
        const tempImg = new Image();
        tempImg.src = highResSrc;
        tempImg.onload = () => {
          img.src = highResSrc;
        };
      }
    }
  }
};
</script>

<style scoped>
.thumbnail-container {
  display: flex;
  flex-direction: row; /* 가로로 일렬 배치 */
  flex-wrap: wrap; /* 화면이 줄어들면 다음 줄로 넘어감 */
  justify-content: flex-start; /* 왼쪽 정렬 */
}

.thumbnail-container__wrap {
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
}

.thumbnail-container__wrap img {
  width: 250px;   /* 가로 크기 */
  height: 250px;  /* 세로 크기 */
  object-fit: cover; /* 이미지 비율 유지 */
  transition: transform 0.2s ease;
}

.thumbnail-container__image {
  display: flex;
}

.thumbnail-container__description {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 1em;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, opacity 0.2s ease;
  text-align: center;
  border-radius: 10px;
}

.thumbnail-container__wrap:hover .thumbnail-container__description {
  visibility: visible;
  opacity: 1;
}

/* 타블렛 이하 화면 스타일 */
@media (max-width: 768px) {
  .thumbnail-container {
    flex-direction: column; /* 세로 정렬 */
  }
  
  .thumbnail-container__wrap img {
    width: 100%;      /* 화면 폭에 맞게 */
    height: auto;     /* 자동 높이 */
    max-width: 100vw;
    margin-right: 0;  /* 오른쪽 간격 제거 */
  }
  
  .thumbnail-container__wrap {
    width: 100%;
    object-fit: cover;
  }
}
</style>