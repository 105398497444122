<template>
  <div id="app">
    <div class="menu-container">
      <div class="menu-container__wrap">
        <router-link class="menu-container__link" :to="{ name: 'MainView' }">
          <h3 class="menu-container__title menu-container__title--main">{{ siteTitle }}</h3>
        </router-link>

        <div class="menu-container__link" @click="toggleDropdown">
          <h3 class="menu-container__title">Artists</h3>
          <div class="dropdown-menu" v-if="isDropdownOpen">
            <div
              v-for="artist in artists"
              :key="artist.route"
              class="dropdown-menu__item"
              @click.stop="navigateToArtist(artist.route)"
            >
              {{ artist.name }}
            </div>
          </div>
        </div>

        <router-link class="menu-container__link" :to="{ name: 'ProjectView' }">
          <h3 class="menu-container__title">Project</h3>
        </router-link>
        <router-link class="menu-container__link" :to="{ name: 'PublicView' }">
          <h3 class="menu-container__title">Publication</h3>
        </router-link>
        <router-link class="menu-container__link" :to="{ name: 'AboutView' }">
          <h3 class="menu-container__title">About</h3>
        </router-link>
      </div>
    </div>

    <div class="spacer"></div>
    <div class="content">
      <router-view />
    </div>

    <footer class="footer">
      <span class="footer-text">©Sizak and Artists, 2024</span>
    </footer>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'App',
  setup() {
    // 사이트 제목
    const siteTitle = ref('SIZAK 시작');

    // 아티스트 데이터
    const artists = ref([
      { name: 'Jung Youngdon 정영돈', route: 'YoungdonJung' },
      { name: 'Kim Chankyu 김찬규', route: 'ChankyuKim' },
      { name: 'Kim Hyungsik 김형식', route: 'HyungsikKim' },
      { name: 'Kim Jaeyeon 김재연', route: 'JaeyeonKim' },
      { name: 'Ahn Jungjin 안정진', route: 'JungjinAhn' },
      { name: 'Jung Jihyun 정지현', route: 'JihyunJung' },
      { name: 'Kim Taejoon 김태중', route: 'TaejoongKim' },
      { name: 'Yun Taejun 윤태준', route: 'TaejunYun' },
      { name: 'Yoo Youngjin 유영진', route: 'YoungjinYoo' },
      { name: 'Choi Eunjoo 최은주', route: 'EunjuChoi' },
      { name: 'Jung Chanmin 정찬민', route: 'ChanminJung' },
      { name: 'Jung Youngho 정영호', route: 'YounghoJung' },
      { name: 'Jung Seungwon 정승원', route: 'SeungwonJung' },
      { name: 'Cristian Doeller 크리스티안 도엘러', route: 'CristianDoeller' },
      { name: 'Lee Hyeonwoo 이현우', route: 'HyeonwooLee' },
      { name: 'Lee Yeeun 이예은', route: 'YeeunLee' },
      { name: 'Zoe Popp 조이 폽', route: 'ZoePopp' },
      { name: 'Jang Soyoung 장소영', route: 'SoyoungJang' },
      { name: 'Yoo Hyejeong 유혜정', route: 'HyejeongYoo' },
    ]);

    const router = useRouter();
    const isDropdownOpen = ref(false);

    // 아티스트 선택 시 드롭다운 닫고 해당 라우트로 이동
    const navigateToArtist = (artistRoute) => {
      isDropdownOpen.value = false;
      router.push({ name: 'ArtistProfileView', params: { artistId: artistRoute } });
    };

    // 드롭다운 토글
    const toggleDropdown = () => {
      isDropdownOpen.value = !isDropdownOpen.value;
    };

    return {
      siteTitle,
      artists,
      isDropdownOpen,
      navigateToArtist,
      toggleDropdown,
    };
  },
};
</script>

<style scoped>
/* @font-face를 사용하여 커스텀 폰트 적용 (CDN을 통해 불러오기) */
@font-face {
  font-family: 'SUIT';
  src: url('https://cdn.jsdelivr.net/gh/sun-typeface/SUIT/fonts/variable/woff2/SUIT-Variable.woff2') format('woff2');
  font-weight: 100 900;
  font-display: swap;
}

/* 전체 텍스트에 폰트 적용 */
* {
  font-family: 'SUIT', sans-serif;
}

.menu-container {
  width: 100%;
}

.menu-container__wrap {
  display: flex;
  justify-content: space-between;
}

.menu-container__link {
  text-decoration: none;
  position: relative;
  cursor: pointer;
}

.menu-container__title {
  font-size: 16px;
  cursor: pointer;
}

.menu-container__title--main {
  color: #c41a22;
  font-weight: 600;
  text-decoration: underline;
}

.router-link-active .menu-container__title {
  position: relative;
}

.router-link-active .menu-container__title::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 250px;
  height: 1px;
}

.spacer {
  margin: 0.8em 0;
}

.content {
  flex: 1;
  overflow-y: auto;
}

footer {
  text-align: right;
  padding: 0;
  width: 100%;
}

.footer-text {
  font-size: 12px;
  color: gray;
}

.dropdown-menu {
  position: absolute;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 60vw;
  padding: 0.8em;
}

.dropdown-menu__item {
  padding: 8px 15px;
  cursor: pointer;
  font-size: 14px;
}

.dropdown-menu__item:hover {
  background-color: #f1f1f1;
}

@media all and (min-width: 320px) and (max-width: 768px) {
  .menu-container__title {
    font-size: 14px;
  }
}
</style>