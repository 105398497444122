<template>
  <div class="artist-detail-view">
    <div class="artist-detail-view__work-detail" v-if="workDetails">
      <WorkDetailComp :artistId="artistId" :workId="selectedWorkId" />
    </div>
  </div>
</template>

<script>
import WorkDetailComp from '@/components/WorkDetailComp.vue';
import artistWorkDetail from '@/data/artistWorkDetail';

export default {
  name: 'ArtistDetailView',
  components: {
    WorkDetailComp,
  },
  props: {
    artistId: {
      type: String,
      required: true,
    },
    workId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      selectedWorkId: null,
      workDetails: null,
    };
  },
  watch: {
    '$route.params': {
      handler() {
        this.fetchArtistDetails();
        this.resetScroll();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    fetchArtistDetails() {
      this.workDetails = artistWorkDetail[this.artistId] || {};
      // workId가 없으면 workDetails의 첫번째 key를 선택
      this.selectedWorkId = this.workId || Object.keys(this.workDetails)[0];
    },
    resetScroll() {
      // 페이지 이동 시 스크롤 위치를 최상단으로 초기화 (부드러운 스크롤 효과 사용)
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>

<style scoped>
/* 필요한 스타일을 추가하세요 */
</style>